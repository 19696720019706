import React, { useEffect, useState } from 'react'
import {BsChevronUp} from 'react-icons/bs'
const  ScrollToTop= () => { 
   const [showScrollTop, setShowScrollTop] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <div>
         {showScrollTop && (
        <div
          className="fixed bottom-8  z-[1000] hover:shadow-lg text-white rounded-full  hover:scale-100 right-8 cursor-pointer bg-[#fc4442] hover:text-[#fc4442] border-2 border-[#fc4442] transition-all ease-out duration-300 p-3 hover:bg-transparent  shadow-md "
          onClick={scrollToTop}
        >
          <BsChevronUp size={22} fontWeight={500} />
        </div>
      )}
    </div>
  )
}

export default ScrollToTop