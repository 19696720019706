import * as React from "react";

const CustomLeftArrow = ({ onClick }) => (
  <i onClick={() => onClick()} className="custom-left-arrow" />
);
const CustomRightArrow = ({ onClick }) => {
  return <i className="custom-right-arrow" onClick={() => onClick()} />;
};

const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
  const { totalItems, currentSlide } = carouselState;
  return (
    <div className="custom-button-group">
      <div>Current slide is {currentSlide}</div>
      <button onClick={() => previous()}>Previous slide</button>
      <button onClick={() => next()}>Next slide</button>
      <button
        onClick={() => goToSlide(Math.floor(Math.random() * totalItems + 1))}
      >
        Go to a random slide
      </button>
    </div>
  );
};
const CustomButtonGroupAsArrows = ({ next, previous }) => {
  return (
    <div className="absolute w-[86%] hidden justify-between items-center md:flex  top-[50%]  ">
    
      <button onClick={previous}
            className="absolute ml-[-60px]   items-center  p-4 w-[50px] h-[50px]  flex justify-center rounded-[100%] text-black cursor-pointer hover:text-[#fc4442] text-[16px] my-auto border-2 border-black hover:border-[#fc4442]"
           
          >
            <i className="fa fa-solid fa-arrow-left"></i>
          </button>
          <button onClick={next}
            className="items-center  left-[100%] absolute  p-4 w-[50px] h-[50px]  flex justify-center rounded-[100%] text-black cursor-pointer hover:text-[#fc4442] text-[16px] my-auto border-2 border-black hover:border-[#fc4442]"
           
          >
            <i className="fa fa-solid fa-arrow-right"></i>
          </button>
    </div>
  );
};

export {
  CustomLeftArrow,
  CustomRightArrow,
  CustomButtonGroup,
  CustomButtonGroupAsArrows,
};