export const NavLinks=[

    {
        name:'Home',
        link:'/',
        submenu:false,
        
    },
    {
        name:'Portfolio',
        link:'/portfolio',
        submenu:false,
        
    },
    {
        name:'About',
        link:'/about',
        submenu:false,
        sublink:[]
    },
   



]