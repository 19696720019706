import React, { useEffect } from "react";
import "./home.css";
import Projects from "./OurProjects";
import Services from "./Services";
import Testominals from "./Testominals";
import Card from "../About/Components/Card";
import GLOBE from 'vanta/src/vanta.globe';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';

function MainHero() {
  useEffect(() => {
    GLOBE({
      el: "#vanta",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0xfc4442,
      color2: 0xfc4442,
      size: 0.80,
      backgroundColor: 0xffffff,
    });

    AOS.init({ duration: 1000 }); 
  }, []);

  const handleLinkClick = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <div className="mt-4 h-[90vh] w-full z-[-10] absolute md:block overflow-hidden flex items-center justify-center">
        <div id="vanta" className="mt-4 h-[90vh] w-full z-[-10] absolute md:block"></div>
      </div>

      <div className="mx-5 md:mx-10 mt-[28vh] md:mt-[17vh] flex md:text-left md:flex-row min-h-[68vh]">
        <div className="md:w-[46%] md:mt-0 leading-[1.2] w-[80%]">
          <div
            id="data"
            className="text-[42px] lg:text-[4.2vw] font-extrabold mb-[1.4rem] mt-[0vh] md:mt-6 pr-6 whitespace-normal tracking-wide"
            data-aos="fade-right" 
          >
            THink P<span className="text-[#fc4442]">A</span>RTneR
            <span className="text-[#fc4442]">, </span>N
            <span className="text-[#c62c32]">O</span>T AGenCY
          </div>
          <div className="downtext text-[16px] font-light m-[0 0 16px] text-[#1B1C19] mb-4 leading-6">
            Since 2022, we've helped launch 8+ next-generation apps for
            startups and growing companies around the world. Let's Make It
            App'n!&#174;
            <div id="mainherobutton">
              <Link to="/contact">
                <button
                  className="bg-[#fc4442] hover:text-[#fc4442] border-[#fc4442] border-2 mt-4 text-white py-[8px] px-[23px]  rounded-3xl font-bold hover:bg-transparent transition-all duration-500 ease-in-out"
                  onClick={handleLinkClick}
                  data-aos="zoom-in" 
                >
                  Work With Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6" data-aos="fade-up">
        <Services />
      </div>
      <div className="mt-12" data-aos="fade-up">
        <Projects />
      </div>
      <div className="mt-6" data-aos="fade-up">
        <Card />
      </div>
      <div className="mt-20" data-aos="fade-up">
        <Testominals />
      </div>
    </>
  );
}

export default MainHero;
