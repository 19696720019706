import React from 'react'
import Details from './Components/Details'
import Card from './Components/Card'
import Team from '../Teams/Team'
function About() {
  return (
    <>
      <Details />

      <div className='mt-10'>
        <Card />
      </div>
      <Team />
    </>
  )
}

export default About
