import ahmed from '../alphas/ahmed.jpg'
import zain from '../alphas/zain.jpg'
import ammar from '../alphas/ammar.jpg'
import ibrahim from '../alphas/ibrahim.jpg'
import awais from '../alphas/awais.jpg'
import daniyal from '../alphas/daniyal.jpg'
import jawad from '../alphas/jawad.jpeg'
import ansar from '../alphas/ansar.jpeg'
import abdullah from '../alphas/abdullah.jpg'
import narinder from '../alphas/narinder.jpeg'
import nouman from '../alphas/noman.jpg'

export const Data = [
    {
        id:1,
        name: "Ahmed Rohail Awan",
        role:"CEO | Full Stack Developer",
        image:ahmed

    },
    {
        id:2,
        name: "Zain ul Abaideen",
        role:"MERN Stack Developer",
        image:zain

    },
    {
        id:3,
        name: "Ammar Zafar",
        role:"UI/UX Designer",
        image:ammar

    },
    {
        id:4,
        name: "Muhammad Ibrahim",
        role:"React & Next JS Developer",
        image:ibrahim
    },
    {
        id:5,
        name: "Awais Afzal",
        role:"Node JS | Api's Developer",
        image:awais
    },
    {
        id:6,
        name: "Daniyal Waheed",
        role:"AI/ML Engineer",
        image:daniyal
    },
    {
        id:7,
        name: "Jawad Ur Rehman",
        role:"UI/UX Designer",
        image:jawad
    },
    {
        id:8,
        name: "Ansar Hayat",
        role:"React developer",
        image:ansar
    },
    {
        id:9,
        name: "Muhammad Abduallah",
        role:"Angular Developer",
        image:abdullah
    },
    {
        id:10,
        name: "Narinder Kumar",
        role:"Php & Larael Developer",
        image:narinder
    },
    {
        id:11,
        name: "Nouman Khan",
        role:"Wordpress developer",
        image:nouman
    }
];