import React from 'react';
import { data } from './data';
import './home.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CustomButtonGroupAsArrows } from './CustomArrows';


const Testimonials = () => {
  const { testominals } = data;



  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<i key={i} className="fa fa-regular fa-star pl-1 text-[18px]"></i>);
    }
    return stars;
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 790, min: 0 },
      items: 1
    },

  };

  const CustomDot = ({ onClick, active, index, carouselState }) => {
    return (
      <div className=' flex items-center justify-center space-x-2 mt-[10px]'>
        <li
          className={` w-4 h-4 rounded-full ml-2 ${active ? " bg-black w-8 h-3" : " bg-gray-300 "}`}
          onClick={() => onClick()}
        >

        </li>
      </div>
    );
  };



  return (
    <div className="mx-8 relative ">
      <h4 className='title text-center text-[32px] mb-16 '>
        Testimonials
      </h4>

      <div className="  text-white  cursor-pointer md:w-[85%] mx-auto">
        <Carousel
          responsive={responsive}
          autoPlay={true}
          infinite={true}
          customDot={<CustomDot />}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          arrows={false} renderButtonGroupOutside={true} customButtonGroup={<CustomButtonGroupAsArrows />}
          showDots  >

          {testominals.map((card, index) => {

            return (

              <div
                key={index}
                className={`mb-16 md:mb-28 mx-auto w-[95%] lg:w-[90%] ${card.no % 2 === 0 ? 'bg-[#f33a37]' : 'bg-[#272e34]'}  border rounded-2xl p-6 min-h-[270] max-h-[440px] md:h-[270px] lg:h-[290px] xl:h-[270px]`}
              >
                <div className='flex mb-3'>
                  <div className='mr-4'>
                    <img src={card.pic} alt={card.name} className="w-[60px] h-[60px]" />
                  </div>
                  <div className="stars-container">
                    <h4 className="text-[22px]">{card.name}</h4>{renderStars(card.stars)}
                  </div>
                </div>
                <div>
                  <p className="text-[16px]">{card.des}</p>
                </div>
              </div>
            );

          })}
        </Carousel>

      </div>
    </div>

  );
};

export default Testimonials;
