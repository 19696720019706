import React from 'react'
import markhor from '../../Pages/Home/images//alpha.png'
import { Link } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll';
import './footer.css'

function Footer() {
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <div className=' mb-6 md:mb-14  mt-28 overflow-x-hidden md:mx-10 mx-3'>
        <div className='grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1   justify-center  lg:space-x-32 
        lg:justify-around mx-auto  '>
          <div className='gradient  shadow-md p-6  overflow-x-hidden rounded-[50px] '>
            <div className='absolute mx-auto lg:hidden w-[90%] z-[100] flex justify-center'>
              <img src={markhor} alt="Markhor"  className='h-16 sm:h-20   mx-auto' />
             
            </div>
            <h2 className='title md:text-[32px] text-[22px] text-center lg:mt-0 mt-24'>Let’s Make It App’n </h2>
            <div className='lg:flex lg:text-left  mt-3 items-center text-center text text-[#1b1c19]'>
              <img src={markhor} alt="Markhor" className='h-16 md:h-20 hidden lg:block' />
              <div className='pt-0 pl-3 text-[16px]' >
                <div className='hover:text-[#fc4442] '>
                <i class="fa fa-light fa-phone"></i>
                <a href="tel:923475615338"><span className='pl-1'>+92 347 5586847</span></a>
                </div>
                <div className='mt-1 hover:text-[#fc4442] '>
                  <i class="fa fa-thin fa-envelope-open"></i>
                  <a href="mailto:alpharisetech@gmail.com" target="_blank" rel="noreferrer" className='pl-1 mt-6'>alpharisetech@gmail.com</a>
                </div>
              </div>

            </div>
            <input type="text" placeholder='Join email Newsletter...' className='text-inherit text-[14px]  outline-none mt-4 mx-5  border border-[#fc4442]  pl-4 py-3 sm:py-4 pr-11 sm:pr-16 rounded-3xl w-[90%]' />
            <button className='text-[14px] bg-[#fc4442] text-white sm:text-[16px] text-center rounded-[100%] py-[10px] sm:py-[14px] px-3 sm:px-5 relative ml-[-58px] sm:ml-[-65px]  hover:bg-[#c62c32]  transition-all duration-300 ease-out'><i class="fa fa-solid fa-arrow-right" ></i></button>
          </div>


          <div className='text-[1B1C19] lg:ml-[-20px]  '>
            <h4 className='text font-semibold text-[30px] mt-14 md:mt-6 lg:mt-0 text-center lg:text-left'>Menu</h4>
            <ul className='text-center lg:text-left  md:space-y-0 space-y-10 md:grid lg:grid-cols-2 mt-8 md:gap-5 lg:gap-x-20 mx-auto font-thin '>
              <li><Link to='/' className='hover:text-[#fc4442] ' onClick={handleLinkClick}> Home </Link></li>
              <li><Link to='/portfolio' className='hover:text-[#fc4442] ' onClick={handleLinkClick}> Portfolio </Link></li>
              <li><Link to='/about' className='hover:text-[#fc4442] ' onClick={handleLinkClick}> About Us</Link></li>
              <li><Link to='/team' className='hover:text-[#fc4442] ' onClick={handleLinkClick}> Our Team </Link></li>
              <li><Link to='/contact' className='hover:text-[#fc4442] ' onClick={handleLinkClick}> Hire Us </Link></li>
            </ul>
          </div>
          <div className='text-[#1B1C19] mt-14 md:mt-6 lg:mt-0 text-center xl:mx-[150px] lg:text-left '>
            <h3 className='text font-semibold text-[30px] '>Connect on Social</h3>
            <div className='cursor-pointer flex space-x-4 justify-center xl:space-x-0 xl:grid xl:grid-cols-3 gap-x-[-30px]   mt-8 xl:gap-y-3 xl:gap-x-[-130px] lg:mr-[15%]'>
              <div className='socials '>
                <a href="/" target='_blank' rel="noreferrer">
                  <i class="fa fa-brands fa-facebook" id="facebook"></i>
                </a>
              </div>
              <div className='socials '>
                <a href="/" target='_blank' rel="noreferrer">
                  <i class="fa fa-brands fa-twitter" id="twitter"></i>
                </a>
              </div>
              <div className='socials '>
                <a href="/" target='_blank' rel="noreferrer">
                  <i class="fa fa-brands fa-instagram" id="instagram"></i>
                </a>
              </div>
              <div className='socials '>
                <a href="/" target='_blank' rel="noreferrer">
                  <i class="fa fa-brands fa-linkedin" id="linkedin"></i>
                </a>
              </div>
              <div className='socials '>
                <a href="/" target='_blank' rel="noreferrer">
                  <i class="fa fa-brands fa-github" id="github"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" text text-[16px] text-center py-8">
        © Copyright 2024 | AlphaRiseTech
      </div>

    </>
  )
}

export default Footer
