import React, { useEffect } from 'react';
import { Data } from './data/data';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Team() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);

  return (
    <div className="w-full px-[5%] pb-20 mt-10 bg-gray-100">
      <div className="container flex justify-center mx-auto pt-16">
        <div>
          <h2 id="solutiontitle" className='title text-[32px] text-center leading-[1.2] font-normal pb-[2rem]' data-aos="fade-up">OUR Team</h2>
          <p className="text-2xl text-center text-gray-500 pb-6 mx-10" data-aos="fade-up" data-aos-delay="200">The Talented People Behind the Scenes of the Organization</p>
        </div>
      </div>
      <div className="container mx-auto">
        <div role="list" aria-label="Behind the scenes People" className="grid xl:grid-cols-4 gap-x-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
          {Data.map((markhors, index) => (
            <div 
              key={index} 
              role="listitem" 
              className="rounded-2xl relative mt-16 cursor-pointer border-2 shadow-md hover:scale-[1.02] hover:shadow-xl"
              data-aos="fade-up"
              data-aos-delay={index * 10} 
            >
              <div className="rounded overflow-hidden">
                <div className="flex justify-center items-center">
                  <img
                    src={markhors.image}
                    alt="DP"
                    className="rounded-full object-cover h-52 w-52 shadow-md mt-[1rem]"
                  />
                </div>
                <div className="px-6 mb-12 mt-8">
                  <h1 className="font-bold text-xl text-center mb-1">{markhors.name}</h1>
                  <p className="text-gray-800 text-sm text-center">{markhors.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Team;
