import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import "./navbar.css";
import markhor from "../../Pages/Home/images/alpha.png";

import { NavLinks } from "./NavLinks";
import { BsChevronDown } from "react-icons/bs";

function ResponsiveNav() {
  const [open, setOpen] = useState(false);
  const [heading, setHeading] = useState(false);

  const toggleMobileMenu = () => {
    setOpen(!open);
  };
  return (
    <div
      className={`z-[999]  lg:hidden ${open
        ? "bg-black text-white z-50 fixed w-[100%]  h-[100vh] top-0 pt-6 px-2  left-0 right-0"
        : "text-black  "
        }   `}
    >
      <div
        className={`lg:hidden items-center cursor-pointer justify-between  overflow-hidden ${open ? "text-white inline-block mx-auto mt-12" : "text-black flex"
          } `}
      >
        <div className="">
          <div
            className={`${open ? "hidden" : "block"} text-2xl`}
            onClick={toggleMobileMenu}
          >
            <i class="fa fa-bars" id="bar"></i>
          </div>
        </div>
        <div
          className={` ${open ? "block" : "hidden"} absolute right-4 top-6 z-[2000]`}
          onClick={toggleMobileMenu}
        >
          <AiOutlineClose size={30} className=" font-bold" />
        </div>

        <ul
          className={`lg:hidden overflow-x-hidden w-[100%]  absolute h-full bottom-0 z-40 duration-500 ${open ? "top-10" : "top-[-100%]"}`}
        >
          <div className="flex flex-col mt-8 mb-12">
            <div className="flex items-center justify-center">
              <Link to="/">
                <img
                  src={markhor}
                  alt=""
                  className='h-20'
                />
              </Link>
            </div>
            <div className="flex flex-col items-center justify-center uppercase">
              <span className="text-[#c62c32] font-bold text-[1.2rem] md:text-[1.5rem]">Alpha Rise</span>
              <span className="text-white font-semibold text-[1rem] md:text-[1.3rem]">- Tech -</span>
            </div>
          </div>

          {NavLinks.map((li, index) => (
            <>
              <Link
                to={li.link}
                className=" hover:text-[#c62c32] cursor-pointer ease-in-out flex-col items-center justify-center pb-8 flex  mt-0"
                key={index}
              >
                <li>
                  {" "}
                  <span
                    className={`text-2xl font-medium flex-1 flex ${!open && "hidden"
                      } ${li.submenu ? "ml-2" : "ml-0"}`}
                    onClick={() => {
                      if (li.submenu) {
                        setHeading(!heading); // Toggle heading state for submenu item
                      } else {
                        setOpen(!open); // Toggle open state for regular menu item
                      }
                    }}
                  >
                    {li.name}
                  </span>
                  {li.submenu && (
                    <BsChevronDown
                      className={`${heading && "rotate-180"
                        }absolute  mt-[-25px] ml-[110px] `}
                      onClick={() => setHeading(!heading) && setOpen(open)}
                    />
                  )}
                </li>
              </Link>
              <div className="mt-2 flex flex-col justify-center items-center">
                {li.submenu && heading && open && (
                  <ul>
                    {li.sublink.map((submenuItem, index) => (
                      <Link to={submenuItem.link}>
                        <li

                          key={index}
                          className=" text-gray-300 text-xl  items-center gap-x-4 cursor-pointer p-5 hover:text-[#c62c32]  rounded-md "
                          onClick={() => {
                            setOpen(!open);
                          }}
                        >
                          {submenuItem.name}
                        </li>
                      </Link>
                    ))}
                  </ul>
                )}
              </div>
            </>
          ))}

          <li>
            <Link to="/contact" class="cursor-pointer">
              <button onClick={toggleMobileMenu}
                className="px-[25px] py-[10px] mx-auto mt-[1rem] flex  bg-[#c62c32] border-white text-white font-medium rounded-full text-[19px] hover:bg-black transition-all duration-500 ease-in-out "
                id="firstbtn"
              >
                Schedule your Consultation
              </button>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ResponsiveNav;
