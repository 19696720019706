import React, { useState } from 'react';
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'
import { useSpring, animated } from "react-spring";
export default function Card() {
    const [counterOn, setCounterOn] = useState(false)
    const textSpring = useSpring({
        opacity: counterOn ? 1 : 0,
        from: { opacity: 0, },
    });
    return (
        <div>
            <section class="py-10 sm:py-16 lg:py-10">
                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>

                    <div class="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
                        <div class="max-w-2xl mx-auto text-center">
                            <h2 className='title text-[40px] text-center leading-[1.2] font-normal pb-[4rem]'>Why US ?</h2>
                        </div>
                        <animated.div style={textSpring}>
                            <div class="grid grid-cols-1 gap-8 mt-3 lg:mb-5 mb-2 text-center lg:mt-3 sm:gap-x-8 md:grid-cols-3">
                                <div>
                                    <h3 class="font-bold text-7xl">
                                        <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#e44248] to-[#eb595e]">
                                            {counterOn && <CountUp start={0} end={3} duration={3} />
                                            } + </span>
                                    </h3>
                                    <p class="mt-4 text-xl font-medium text-gray-900">Years in business</p>
                                    <p class="text-base mt-0.5 text-gray-500">Creating the successful path</p>
                                </div>

                                <div>
                                    <h3 class="font-bold text-7xl">
                                        <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#e44248] to-[#eb595e]">
                                            {counterOn && <CountUp start={0} end={15} duration={3} />} +
                                        </span>
                                    </h3>
                                    <p class="mt-4 text-xl font-medium text-gray-900">Projects delivered</p>
                                    <p class="text-base mt-0.5 text-gray-500">In last 1 years</p>
                                </div>

                                <div>
                                    <h3 class="font-bold text-7xl">
                                        <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#e44248] to-[#eb595e]">
                                            {counterOn && <CountUp start={0} end={5} duration={3} />}
                                            + </span>
                                    </h3>
                                    <p class="mt-4 text-xl font-medium text-gray-900">Team members</p>
                                    <p class="text-base mt-0.5 text-gray-500">Working for your success</p>
                                </div>
                            </div>
                        </animated.div>
                    </div>
                </ScrollTrigger>
            </section>

        </div>
    )
}
