import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import dasboard from './images/dashboard.png';
import { animateScroll as scroll } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Details() {
  useEffect(() => {

    AOS.init({ duration: 1000 });
  }, []);
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };

  return (
    <div>
      <section
        className="min-h-[90vh] mt-4"
        style={{ backgroundColor: '#f7f7f7' }}

      >
        <div className="px-4 mx-auto max-w-[94%] sm:px-6 lg:px-8">
          <div className="py-10 sm:py-16 lg:py-24">
            <div className="grid items-center grid-cols-1 gap-y-8 lg:grid-cols-2 lg:gap-x-8">
              <div
                className="lg:order-2"
                data-aos="fade-left"
              >
                <img
                  className="w-full shadow-xl rounded-xl"
                  src={dasboard}
                  alt=""
                />
              </div>
              <div
                className="lg:order-1"
                data-aos="fade-right"
              >
                <h2
                  id="solutiontitle"
                  className="title text-[32px] leading-[1.2] font-normal mb-[1rem]"
                  data-aos="fade-up"
                >
                  About Us
                </h2>
                <p
                  className="text-base sm:text-lg md:text-xl text-black py-2"
                  data-aos="fade-up"
                >
                  Proudly serving the world’s top asset finance and leasing companies with smart software technology for over four decades.
                </p>
                <div
                  className="flex flex-col items-start mt-[1rem] sm:space-x-4 sm:flex-row sm:items-center"
                >
                  <button className="bg-[#fc4442] hover:bg-transparent hover:text-[#fc4442] border-[#fc4442] border  text-white text-lg font-bold px-8 py-4 h-fit rounded-xl w-fit shrink-0 md:self-center" onClick={handleLinkClick}> <Link to="/contact">Hire a Pro</Link>  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Details;
