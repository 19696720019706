import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import { animateScroll as scroll } from 'react-scroll';
import markhor from "../../Pages/Home/images/alpha.png";
import ResponsiveNav from "./ResponsiveNav";
import { NavLinks } from "./NavLinks";

function Navbar() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    scroll.scrollToTop();
  };

  return (
    <>
      <div className={`h-24 bg-white flex items-center justify-between px-4 z-[999]`}>
        <div className="flex">
          <div>
            <Link to="/" onClick={() => handleLinkClick('/')}>
              <img
                src={markhor}
                alt=""
                className='h-16 md:h-20'
              />
            </Link>
          </div>
          <div className="flex flex-col items-center justify-center uppercase">
            <span className="text-[#fc4442] font-bold text-[1.2rem] md:text-[1.5rem]">Alpha Rise</span>
            <span className="text-black font-semibold text-[1rem] md:text-[1.2rem]">- Tech -</span>
          </div>
        </div>
        <div>
          <ul className="flex list-none items-center justify-center font-semibold space-x-14">
            {
              NavLinks.map((li, key) => (
                <div 
                  key={key} 
                  className={`right group no-underline  text-[1.2rem]  hidden lg:flex font-semibold`}
                >
                  <Link
                    to={li.link}
                    className={`hover:colorred cursor-pointer hover:text-[#fc4442] ease-in-out ${activeLink === li.link ? 'text-[#fc4442] ' : 'text-black'}`}
                    onClick={() => handleLinkClick(li.link)}
                  >
                    <li className="flex px-4 py-2 rounded">{li.name}</li>
                  </Link>
                </div>
              ))
            }
          </ul>
        </div>
        <div className="hidden lg:flex items-center justify-center">
          <Link to="/contact" class="cursor-pointer">
            <button
              className="px-[25px] py-[10px] bg-[#e44248] text-white font-medium rounded-full text-[1.3vw] border border-[#e44248] hover:bg-transparent hover:text-[#e44248] transition-all duration-500 ease-in-out"
              id="firstbtn"
            >
              Schedule your Consultation
            </button>
          </Link>
        </div>
        {/* mobile */}
        <ResponsiveNav />
      </div>
    </>
  );
}

export default Navbar;
